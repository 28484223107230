/* Setores em PRD */
const setores = {
  departamentoDePessoal: 'Departamento de Pessoal',
  adminstrativo: 'Administrativo',
  TI: 'TI',
  desenvolvimento: 'Desenvolvimento Conecta',
  juridico: 'Jurídico',
  automationcenter: 'Meta Automation Center',
};

const aprovacao = {
  aguardandoAprovacao: 1,
  aprovado: 2,
  reprovado: 3,
  pendente: 4,
};

/* Status de atendimento */
const status = {
  pendente: 1,
  emAtendimento: 2,
  aguardandoUsuario: 3,
  encerrado: 4,
  aguardandoAtendente: 5,
  analise: 6,
};
/* Constantes para Filtro de subcategoria */
const subcategorias = {
  reajusteSalario: 'Reajuste de salário',
};
/* Área de Atuação para setor TI */
const areaAtuacao = [
  {
    label: 'Infraestrutura',
    value: 1,
  },
  {
    label: 'Sistemas Internos',
    value: 2,
  },
];
/* Categorias de fluxo */
const fluxos = {
  recisao: 1,
  solicitarMotoboy: 4,
  solicitarCoffeeBreak: 7,
  reajuste: 8,
  comprasFixas: 36,
  comprasOrdinarias: 37,
  comprasTecnicasNacionais: 38,
  comprasEPS: 39,
  comprasTecnicasInternacionais: 40,
  realocacao: 41,
  criarFornecedoresDadosMestres: 42,
  modificarFornecedor: 43,
  criarMaterialServico: 44,
  criarClientesDadosMestres: 45,
  modificarCliente: 46,
  criarServicoCliente: 47,
};

module.exports = {
  setores,
  aprovacao,
  status,
  fluxos,
  areaAtuacao,
  subcategorias,
};
