<template>
  <div>
    <Snackbar
      :label="msg"
      v-model="snackbarPesquisa"
      top
      right
      :timeout="2500"
      :tipo="tipoSnack"
    />
    <vueTagsInput
      class="filtro"
      element-id="tags"
      v-model="tagsInseridas"
      :placeholder="formTitle"
      :existing-tags="tagSugestoes"
      :typeahead="true"
      typeahead-style="dropdown"
      :typeahead-activation-threshold="0"
      :typeahead-hide-discard="true"
      :add-tags-on-blur="true"
      :limit="quantidadeTags"
      :hide-input-on-limit="true"
      @tag-removed="onTagRemoved"
      @tag-added="onTagAdded"
      @tags-changed="newTags => tagsInseridas = newTags"
    />
    <div
      v-if="limpar"
      class="limpar"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            @click="limparFiltro"
            v-on="on"
          >mdi-close</v-icon>
        </template>
        <span>Limpar</span>
      </v-tooltip>
    </div>
    <Botao
      v-if="tipoFiltro !=='searchUsuarios'"
      class="ml-3"
      type="button"
      :label="tipoFiltro === 'searchFAQ' ? 'Buscar' : 'Filtrar'"
      tipo="primary"
      :callback="preparaBusca"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import vueTagsInput from '@voerro/vue-tagsinput';
import '@voerro/vue-tagsinput/dist/style.css';
import isEmpty from 'lodash/isEmpty';
import { setores } from '../Constants.js';
import Botao from './Botao.vue';
import Snackbar from './Snackbar';
import { getMaxPermission } from '../utils/utilities';
import EventBus from '../plugins/EventBus';

export default {
  name: 'filtroAtendimento',
  components: {
    vueTagsInput,
    Botao,
    Snackbar,
  },
  props: {
    tipoFiltro: VueTypes.string.isRequired,
    quantidadeTags: VueTypes.number.isRequired,
  },
  data() {
    return {
      msg: '',
      tipoSnack: 'error',
      snackbarPesquisa: false,
      alerta: '',
      limpar: false,
      tagCategorias: [],
      tagSugestoes: [],
      tagsInseridas: [],
      tags: [],
      pendente: [],
      analise: [],
      emAtendimento: [],
      aguardandoUsuario: [],
      encerrado: [],
      aguardandoAtendente: [],
      permissaoSetor: getMaxPermission(this.$store),
    };
  },
  created() {
    this.sugereCategorias();
    this.insereUsuarios();
    EventBus.$on('limpaFiltroUsuarios', () => {
      this.limparFiltro();
    });
  },
  computed: {
    formTitle() {
      return this.tipoFiltro === 'searchUsuarios' ? 'Insira o Usuario' : '';
    },
    isCategoria() {
      return this.tags.length >= 2 && this.tags.length % 2 === 0;
    },
    isDescricaoOrId() {
      return this.tags[0] === 'descricao' || this.tags[0] === 'idAtendimento';
    },
  },

  methods: {
    insereUsuarios() {
      if (this.tipoFiltro === 'searchUsuarios') this.getSugestoes('usuarios');
    },

    async getSugestoes(categorias) {
      try {
        if (this.tipoFiltro === 'searchFAQ') {
          const { data: response } = await this.$http.get(
            `/sugestoes-busca-faq/${categorias}/${this.permissaoSetor.id_permissao}/${this.permissaoSetor.id_setor}`,
          );
          this.tagSugestoes = response.map(item => ({
            value: item.value,
            key: item.id,
          }));
          this.tagSugestoes = this.tagSugestoes.filter(item => item.desativado === false);
        } else if (this.tipoFiltro === 'searchAtendimentos' && (categorias !== 'descricao' && categorias !== 'idAtendimento')) {
          const { data: response } = await this.$http.get(
            `/sugestoes-busca/${categorias}/${this.permissaoSetor.id_permissao}/${this.permissaoSetor.id_setor}`,
          );
          this.tagSugestoes = response.map(item => ({
            value: item.value,
            key: item.id,
          }));
        } else if (this.tipoFiltro === 'searchUsuarios') {
          const { data: response } = await this.$http.get(
            `/sugestoes-busca/usuariosPermissao/${this.permissaoSetor.id_permissao}/${this.permissaoSetor.id_setor}`,
          );
          const responseTratado = response.map(item => ({
            value: item.value,
            key: item.id,
            permissao_id: item.permissao_id,
          }));

          this.tagSugestoes = responseTratado.filter(user => user.permissao_id === 1);
        }
      } catch (error) {
        this.ativaSnackbar('sugestoes');
        console.error(error);
      }
    },

    sugereCategorias() {
      if (this.tipoFiltro === 'searchFAQ') {
        this.tagCategorias = [
          { key: 'categoriasFAQ', value: 'Categoria' },
          { key: 'subcategoriasFAQ', value: 'Subcategoria' },
        ];
      } else if (this.tipoFiltro === 'searchAtendimentos') {
        this.tagCategorias = [
          { key: 'idAtendimento', value: 'Nº Atendimento' },
          { key: 'descricao', value: 'Descrição' },
          { key: 'atendentes', value: 'Responsável' },
          { key: 'categorias', value: 'Categoria' },
          { key: 'subcategorias', value: 'Subcategoria' },
          { key: 'usuarios', value: 'Usuário' },
        ];
      }

      if (this.permissaoSetor.setor_nome === setores.TI && this.tipoFiltro !== 'searchUsuarios') this.tagCategorias.push({ key: 'areaAtuacao', value: 'Área de atuação' });
      if (this.permissaoSetor.id_permissao === this.$store.getters.listaPermissoes.gestorgeral) {
        if (this.tipoFiltro === 'searchFAQ') this.tagCategorias.push({ key: 'setorFAQ', value: 'Setor' });
        if (this.tipoFiltro === 'searchAtendimentos') this.tagCategorias.push({ key: 'setores', value: 'Setor' });
      }
      this.tagSugestoes = this.tagCategorias;
    },

    onTagRemoved(tag) {
      this.tagsInseridas.forEach((element, index) => {
        if (element.key === tag.key) {
          this.tagsInseridas.splice(index, 1);
        }
      });
      const tamanho = this.tagsInseridas.length - 1;
      if (this.tipoFiltro === 'searchUsuarios') this.insereUsuarios();
      if (isEmpty(this.tagsInseridas) || (tamanho % 2 !== 0)) {
        this.sugereCategorias();
        this.$emit('changeFiltro', []);
      } else {
        this.getSugestoes(this.tagsInseridas[tamanho].key);
      }
      if (isEmpty(this.tagsInseridas)) {
        this.limpar = false;
      }
    },

    onTagAdded(tag) {
      // Verifica se o inserido pelo usuário é uma categoria
      this.limpar = this.tipoFiltro !== 'searchUsuarios';
      const result = this.tagCategorias.find(element => (element.value === tag.value));
      if (this.tipoFiltro === 'searchUsuarios') {
        this.$emit('changeFiltro', this.tagsInseridas);
      }
      if (result) {
        this.getSugestoes(tag.key);
      } else {
        this.sugereCategorias();
      }
    },

    async preparaBusca() {
      this.tags = this.formataTagsInseridas();
      if (isEmpty(this.tags)) {
        this.$emit('changeFiltro', []);
      }
      if (this.isDescricaoOrId) {
        this.pendente = await this.filtraAtendimentos(1);
        this.analise = await this.filtraAtendimentos(6);
        this.aguardandoAtendente = await this.filtraAtendimentos(5);
        this.emAtendimento = await this.filtraAtendimentos(2);
        this.aguardandoUsuario = await this.filtraAtendimentos(3);
        this.encerrado = await this.filtraAtendimentos(4);

        this.$emit('changeFiltro', [this.pendente, this.analise, this.aguardandoAtendente, this.emAtendimento, this.aguardandoUsuario, this.encerrado]);
      } else if (this.isCategoria && this.tipoFiltro === 'searchAtendimentos') {
        this.pendente = await this.filtraAtendimentos(1);
        this.analise = await this.filtraAtendimentos(6);
        this.aguardandoAtendente = await this.filtraAtendimentos(5);
        this.emAtendimento = await this.filtraAtendimentos(2);
        this.aguardandoUsuario = await this.filtraAtendimentos(3);
        this.encerrado = await this.filtraAtendimentos(4);

        this.$emit('changeFiltro', [this.pendente, this.analise, this.aguardandoAtendente, this.emAtendimento, this.aguardandoUsuario, this.encerrado]);
      } else if (this.isCategoria && this.tipoFiltro === 'searchFAQ') {
        this.filtraFAQs();
      } else if ((!isEmpty(this.tags)) || (this.isCategoria)) {
        this.ativaSnackbar('parametros');
      }
    },

    async filtraAtendimentos(status) {
      try {
        const { data: response } = await this.$http.post(
          `/busca-geral/${status}`, { tags: this.tags, setor: this.permissaoSetor.id_setor, permissao: this.permissaoSetor.id_permissao },
        );
        return response;
      } catch (error) {
        this.ativaSnackbar('request');
        return console.error(error);
      }
    },

    async filtraFAQs() {
      try {
        const { data: response } = await this.$http.post(
          '/filtra-faqs', { tags: this.tags },
        );
        this.$emit('changeFiltro', response);
      } catch (error) {
        this.ativaSnackbar('request');
        console.error(error);
      }
    },

    formataTagsInseridas() {
      const tags = [];
      this.tagsInseridas.forEach((element, index) => {
        if (index % 2 === 0) {
          tags.push(element.key);
        } else {
          tags.push(element.value);
        }
      });
      return tags;
    },

    limparFiltro() {
      this.sugereCategorias();
      this.tagsInseridas = [];
      this.limpar = false;
      this.$emit('changeFiltro', []);
      if (this.tipoFiltro === 'searchUsuarios') this.insereUsuarios();
    },

    ativaSnackbar(tipoErro) {
      this.alerta = 'error';
      if (tipoErro === 'sugestao') {
        this.msg = 'Erro ao carregar sugestões de pesquisa.';
      } else if (tipoErro === 'request') {
        this.message = 'Erro ao efetuar busca.';
      } else {
        this.msg = 'Por Favor preencha os campos corretamente para pesquisar.';
      }
      this.snackbarPesquisa = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchButton {
  text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px;
  font-size: 16px !important;
  letter-spacing: 0;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  font-weight: bold;
  padding: 0px 40px !important;
  &:hover {
    color: #bfbfbf;
  }
}
.filtro {
  width: 100%;
  padding-left: 0px !important;
}

.limpar {
  display: flex;
  margin-left: -30px;
  z-index: 999;
}
</style>

<style lang="scss">
.tags-input-wrapper-default {
  padding: 4px 6px;
  min-height: 100%;
}

.tags-input-wrapper-default.active {
  border: 1px solid #0056a8;
  box-shadow: none;
}

.tags-input-typeahead-item-default {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #303030;
}

.tags-input-typeahead-item-highlighted-default {
  background-color: #b3d4fc;
  color: #303030;
}

.typeahead-dropdown {
  width: auto;
  padding: 0px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 170px;
}

.tags-input-badge {
  font-size: 14px;
  font-weight: normal !important;
}

.tags-input-badge-pill {
  border-radius: 2px;
}

.tags-input-badge-selected-default {
  margin-bottom: 4px;
  color: #303030;
  background-color: #d9d9d9;
}

.tags-input-remove::after {
  background: #0056a8;
}

.tags-input-remove::before {
  background: #0056a8;
}
</style>
