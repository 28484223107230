<template>
  <div id="comprasTecnicasNacionais">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Compras Técnicas - Nacionais</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col cols="4">
          <v-autocomplete
            v-model="unidade"
            :items="unidades"
            label="Centro / Unidade"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="fornecedorSelecionado"
            :items="fornecedores"
            label="Escolha um Fornecedor"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-autocomplete
            class="mb-2 alert-fild-required"
            v-for="(item, index) in pedidos"
            :key="index"
            :items="materiais"
            v-model="item.material"
            label="Material/Serviços"
            outlined
            dense
          ></v-autocomplete>
        </v-col>


        <v-col cols="2">
          <v-text-field
            class="mb-1 alert-fild-required"
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.qtTotal"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
            @change="calculaPreco()"
          ></v-text-field>
        </v-col>


        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in pedidos"
            :key="index"
            class="mb-1 alert-fild-required"
            v-money="money"
            v-model="item.orcamento"
            label="Valor unitário"
            outlined
            dense
            @change="calculaPreco()"
          ></v-text-field>
        </v-col>


        <v-col cols="2">
          <v-autocomplete
            v-for="(item, index) in pedidos"
            :key="index"
            class="mb-2 alert-fild-required"
            v-model="item.cc"
            :items="centrosCustos"
            label="Centro de custo"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>


        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in pedidos"
            :key="index"
            class="mb-1 alert-fild-required"
            v-model="item.orcamentoTotal"
            label="Valor total do item"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>

        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewMateria()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um material ou serviço.</span>
          </v-tooltip>
          <v-tooltip v-if="pedidos.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldMaterial()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um material ou serviço.</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model.lazy="orcamentoTotal"
            label="Valor total do pedido"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="condicaoPagamentoSelecionado"
            :items="condicoesPagamento"
            label="Condição de pagamento"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-textarea
            v-model="endereco"
            label="Local de entrega"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>

        <v-col>
          <v-checkbox
            v-model="confirmacaoRecebimento"
            label="Confirmação de Recebimento"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'comprasTecnicasNacionais.vue',
  components: { Snackbar },
  directives: { money: VMoney, mask },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      empresa: '',
      empresas: [
        'Meta Sales SC',
        'Meta Consulting Services MC',
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],

      unidade: '',
      unidades: ['Carregando...'],

      fornecedorSelecionado: '',
      fornecedores: ['Carregando...'],

      pedidos: [{
        material: '', qtTotal: '', orcamento: '', orcamentoTotal: '', cc: '',
      }],
      materiais: ['Carregando...'],

      objetoCentroCusto: [],
      aprovadoresList: [],
      centrosCustos: ['Carregando...'],

      condicaoPagamentoSelecionado: '',
      condicoesPagamento: [
        '1 dia', '2 dias', '3 dias', '4 dias', '5 dias', '6 dias', '7 dias', '8 dias',
        '9 dias', '10 dias', '11 dias', '12 dias', '13 dias', '14 dias', '15 dias', '16 dias',
        '17 dias', '18 dias', '19 dias', '20 dias', '21 dias', '22 dias', '23 dias', '24 dias',
        '25 dias', '26 dias', '27 dias', '28 dias', '29 dias', '30 dias', '31 dias', '32 dias',
        '33 dias', '34 dias', '35 dias', '45 dias', '50 dias', '60 dias', '90 dias', '120 dias',
        '180 dias', '15/30', '15/30/45/60', '30/60', '30/60/90', '30/60/90/120', '30/60/90/120/150',
        '30/60/90/120/150/180', '30/60/90/120/150/180/210', '30/60/90/120/150/180/210/240',
        '30/60/90/120/150/180/210/240/270', '30/60/90/120/150/180/210/240/270/300',
        '30/60/90/120/150/180/210/240/270/300/330', '30/60/90/120/150/180/210/240/270/300/330/360',
        'BLOQUEIO LEASING', 'CARTÃO VR (DEP PESSOAL)', 'CARTÃO CRÉDITO',
      ],

      orcamentoTotal: '',

      endereco: '',

      confirmacaoRecebimento: '',

      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  async created() {
    await this.getCentrosUnidade();
    await this.getFornecedores();
    await this.getMateriais();
    await this.getCentrosCustos();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && !!this.checkPedidos()
      && !!this.orcamentoTotal
      && !!this.condicaoPagamentoSelecionado);
    },
  },
  methods: {
    checkMoney(valorTotal) {
      return valorTotal !== 'R$ 0,00';
    },

    checkPedidos() {
      if (this.pedidos.length === 1) {
        return (!!this.pedidos[0].material
              && !!this.pedidos[0].qtTotal
              && this.checkMoney(this.pedidos[0].orcamento)
              && !!this.pedidos[0].orcamentoTotal
              && !!this.pedidos[0].cc);
      }
      if (this.pedidos.length === 2) {
        return (!!this.pedidos[1].material
              && !!this.pedidos[1].qtTotal
              && this.checkMoney(this.pedidos[1].orcamento)
              && !!this.pedidos[1].orcamentoTotal
              && !!this.pedidos[1].cc);
      }
      if (this.pedidos.length === 3) {
        return (!!this.pedidos[2].material
              && !!this.pedidos[2].qtTotal
              && this.checkMoney(this.pedidos[2].orcamento)
              && !!this.pedidos[2].orcamentoTotal
              && !!this.pedidos[2].cc);
      }

      return false;
    },

    async getMateriaisServicos() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/material');
        this.materiais = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getCentrosUnidade() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-filiais');
        this.unidades = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getFornecedores() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/fornecedor');
        this.fornecedores = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getMateriais() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/material');
        this.materiais = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getCentrosCustos() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-custo');
        this.centrosCustos = response.map(item => item.descricaoItens);
        this.objetoCentroCusto = response;
      } catch (error) {
        console.error(error);
      }
    },

    getDadosCentroCusto(centrosCusto) {
      try {
        centrosCusto.forEach((item) => {
          this.objetoCentroCusto.filter((element) => {
            if (item.cc.includes(element.descricaoItens)) {
              this.aprovadoresList.push(element);
            }

            return this.aprovadoresList;
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    calculaPreco() {
      let total = 0;
      this.pedidos.forEach((item, index) => {
        let justNumbers = item.orcamento.replace('R$ ', '').replace('.', '').replace(',', '.');
        justNumbers = parseFloat(justNumbers).toFixed(2);
        const calculation = (justNumbers * item.qtTotal);
        total += calculation;
        this.pedidos[index].orcamentoTotal = this.formatNumber(calculation);
      });
      this.orcamentoTotal = this.formatNumber(total);
    },

    formatNumber(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency', currency: 'BRL',
      });

      return formatter.format(value);
    },

    addNewMateria() {
      if (this.pedidos.length < 3) {
        this.pedidos.push({
          material: '', qtTotal: '', orcamento: '', cc: '',
        });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 3 Materiais/Serviços por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldMaterial() {
      this.pedidos.pop();
    },

    inputValueInAprovadores(dadosFluxo) {
      const aprovadoresWithValue = dadosFluxo.aprovadoresList.map((item, index) => ({
        ...item,
        valor: dadosFluxo.pedidos[index].orcamentoTotal,
      }));

      return aprovadoresWithValue;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.unidade) respostasModel.column_campo_2 = dadosFluxo.unidade;
      if (dadosFluxo.fornecedor) respostasModel.column_campo_3 = dadosFluxo.fornecedor;
      if (dadosFluxo.pedidos[0]) {
        respostasModel.column_campo_4 = dadosFluxo.pedidos[0].material;
        respostasModel.column_campo_5 = dadosFluxo.pedidos[0].qtTotal;
        respostasModel.column_campo_6 = dadosFluxo.pedidos[0].orcamento;
        respostasModel.column_campo_7 = dadosFluxo.pedidos[0].orcamentoTotal;
        respostasModel.column_campo_8 = dadosFluxo.pedidos[0].cc;
      }
      if (dadosFluxo.pedidos[1]) {
        respostasModel.column_campo_9 = dadosFluxo.pedidos[1].material;
        respostasModel.column_campo_10 = dadosFluxo.pedidos[1].qtTotal;
        respostasModel.column_campo_11 = dadosFluxo.pedidos[1].orcamento;
        respostasModel.column_campo_12 = dadosFluxo.pedidos[1].orcamentoTotal;
        respostasModel.column_campo_13 = dadosFluxo.pedidos[1].cc;
      }
      if (dadosFluxo.pedidos[2]) {
        respostasModel.column_campo_14 = dadosFluxo.pedidos[2].material;
        respostasModel.column_campo_15 = dadosFluxo.pedidos[2].qtTotal;
        respostasModel.column_campo_16 = dadosFluxo.pedidos[2].orcamento;
        respostasModel.column_campo_17 = dadosFluxo.pedidos[2].orcamentoTotal;
        respostasModel.column_campo_18 = dadosFluxo.pedidos[2].cc;
      }
      if (dadosFluxo.orcamentoTotal) respostasModel.column_campo_19 = dadosFluxo.orcamentoTotal;
      if (dadosFluxo.condicaoPagamento) {
        respostasModel.column_campo_20 = dadosFluxo.condicaoPagamento;
      }
      if (dadosFluxo.endereco) respostasModel.column_campo_21 = dadosFluxo.endereco;
      if (dadosFluxo.confirmacaoRecebimento) {
        respostasModel.column_campo_22 = dadosFluxo.confirmacaoRecebimento;
      }

      respostasModel.validation = true;

      const aprovadoresWithValue = this.inputValueInAprovadores(dadosFluxo);
      respostasModel.aprovadoresList = aprovadoresWithValue;
      respostasModel.usuarioSolicitante = this.$store.getters.isUsuario.nome;

      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.getDadosCentroCusto(this.pedidos);
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          unidade: this.unidade,
          fornecedor: this.fornecedorSelecionado,
          pedidos: this.pedidos,
          orcamentoTotal: this.orcamentoTotal,
          condicaoPagamento: this.condicaoPagamentoSelecionado,
          endereco: this.endereco,
          confirmacaoRecebimento: this.confirmacaoRecebimento,
          aprovadoresList: this.aprovadoresList,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
